<template>
  <v-app>
    <!--HEADER-->
    <v-app-bar
      app
      color="primary"
      elevate-on-scroll
      dark
      clipped-left
      clipped-right
      fixed-tabs
      :src="info.background_image">
      <template v-slot:img="{ props }">
        <div style="opacity: 0.1">
          <v-img v-bind="props"/>
        </div>
      </template>
      <v-app-bar-nav-icon @click="menu = !menu" />
      <v-toolbar-title v-if="!$vuetify.breakpoint.smAndDown" class="ml-2">{{ info.name }}</v-toolbar-title>
      <v-spacer/>
      <!--TOP RIGHT MENU-->
      <v-btn @click="show_right_sidebar = !show_right_sidebar" dark icon class="mr-2">
        <v-badge v-if="record.notifications.length > 0" color="red" dot>
          <v-icon small>mdi-message</v-icon>
        </v-badge>
        <v-icon v-else small>mdi-message</v-icon>
      </v-btn>
      <small>{{ record.person.name }}</small>
      <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" class="ml-2">
            <v-avatar size="40">
              <v-icon color="white">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>{{ $store.getters.translate("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <!--LEFT MENU-->
    <v-navigation-drawer
      id="main-sidebar"
      v-model="menu"
      :width="350"
      mobile-breakpoint="960"
      clipped
      app
      v-bar
      mini-variant-width="70">
      <!--MENU ITEMS-->
      <v-list>
        <v-list-item
          v-for="item in menu_items"
          @click="clickTab(item.tab)"
          :key="item.tab"
          link
          dense>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!--ADDITIONAL INFO AND LANGUAGE-->
      <template v-slot:append>
        <v-divider/>
        <div class="pa-5 text-center">
          <img v-if="info.logo" style="max-width: 150px" :src="info.logo"/><br />
          <small>
            <strong>{{ info.name }}</strong><br />
            <span v-if="info.address">{{ info.address }}</span><br />
            <span v-if="info.phone">{{ info.phone }}</span><br />
            <a v-if="info && info.email" :href="'mailto:' + info.email">{{ info.email }}</a><br />
          </small>
        </div>
      </template>
    </v-navigation-drawer>
    <!--BODY CONTENT-->
    <v-main style="background-color: #eef5f9">
      <v-container cols="12">
        <v-row>
          <v-col>
            <v-tabs-items style="background-color: #eef5f9" v-model="tab">
              <!--HOME TAB (FIRST, AVAILABLE FOR ALL)-->
              <v-tab-item style="background-color: #eef5f9">
                <Header :name="'home'" :icon="'mdi-home'"/>
                <!--WELCOME MESSAGE-->
                <v-container>
                  <v-skeleton-loader v-if="loading" type="card-header, article, actions,card-footer"/>
                  <v-card v-if="info.portal_welcome" class="pa-8">
                    <v-card-text>
                      <div v-html="info.portal_welcome"></div>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-tab-item>
              <!--CONTACT INFORMATION (SECOND, AVAILABLE FOR ALL)-->
              <v-tab-item style="background-color: #eef5f9">
                <Header :name="'your_contactinformation'" :icon="'mdi-account'"/>
                <v-container fluid>
                  <base-profile
                      :save_loading="loading"
                      :module_name="page.name"
                      :record="record"
                      @change="save"
                      @clean_cache="cleanCache"
                      :user_type="record.type"
                      :profile="true"/>
                </v-container>
              </v-tab-item>
              <v-tab-item style="background-color: #eef5f9" v-if="record.type === 'contact'">
                <Header :name="'workers'" :icon="$icons['workers']"/>
                <v-container fluid>
                  <v-card class="mb-5">
                    <v-card-text>
                      <v-text-field
                          v-model="search"
                          @click:clear="clearSearch"
                          :label="$store.getters.translate('search')"
                          single-line
                          hide-details
                          clearable
                          append-icon="mdi-magnify"/>
                    </v-card-text>
                  </v-card>
                    <v-expansion-panels>
                      <v-expansion-panel v-for="(worker, key) in $lodash.sortBy(getWorkers, 'name')" :key="key">
                        <v-expansion-panel-header>
                          <v-toolbar dense flat tile>
                            <v-toolbar-title>{{ worker.name }}</v-toolbar-title>
                            <v-spacer/>
                            <small class="text-right">{{ worker.companies[0].name }}</small>
                          </v-toolbar>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pt-3 pb-3">
                          <base-notes
                            class="mt-5"
                            :notes="worker.notes"
                            :user_id="record.id"
                            :user_type="record.type"
                            :user_phone_number="record.person.mobilenumber"
                            @change="load"/>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                </v-container>
              </v-tab-item>
              <!--NOTES DATA (FORTH, AVAILABLE FOR WORKERS ONLY)-->
              <v-tab-item style="background-color: #eef5f9" v-if="record.type === 'worker'">
                <Header :name="'documents'" :icon="$icons['notes']"/>
                <v-container fluid>
                    <base-notes
                      :notes="notes"
                      :user_id="record.id"
                      :user_type="record.type"
                      :user_phone_number="record.person.mobilenumber"
                      @change="getNotes"/>
                    <span v-if="notes.length === 0">{{ $store.getters.translate("nothing_found") }}</span>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
            <right-sidebar @refresh="load" :record="record" :show_sidebar="show_right_sidebar" @close="show_right_sidebar = false"/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import BaseProfile from "../../../components/commonComponents/BaseProfile";
import BaseNotes from "./includes/BaseNotes";
import RightSidebar from "./includes/rightSideBar";
import Header from "./includes/Header";
import helpFunctions from "../../../plugins/helpFunctions";

export default {
  name: "Layout",
  title: process.env.VUE_APP_TITLE ? process.env.VUE_APP_TITLE : "Voltado",
  components: {
    BaseProfile,
    BaseNotes,
    RightSidebar,
    Header,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("portal"),
        name: "portal",
      },
      menu_items: [
        {
          title: this.$store.getters.translate("home"),
          icon: "mdi-home",
          tab: 0,
        },
        {
          title: this.$store.getters.translate("your_contactinformation"),
          icon: "mdi-account",
          tab: 1,
        },
      ],
      show_right_sidebar: true,
      menu: !this.$vuetify.breakpoint.smAndDown,
      record: {
        language: "nl",
        person: {
          first_name: "",
          insertion: "",
          last_name: "",
          initials: "",
          phonenumber: "",
          mobilenumber: "",
          emailaddresses: [],
          billingnumber: "",
          place_of_birth: "",
          country_of_birth_id: 1,
          nationality_id: 1,
          date_of_birth: "",
          gender: "",
          financial_name: "",
          contact: {
          },
          worker: {
            id: null,
            reference: "",
            notes: [],
          },
        },
        type: 'employee',
        notifications: [],
        notes: [],
        settings: { portal_welcome: null },
      },
      loading: false,
      search: "",
      tab: 0,
      info: {},
      notes: [],
      show_sidebar: true
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
    if (this.$vuetify.breakpoint.smAndDown) {
      this.show_right_sidebar = false;
    }
    this.load();
    this.getInfo();
  },
  methods: {
    load() {
      this.loading = true;
      let person = this.record.person;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name)
        .then((response) => {
          this.loading = false;
          this.record = response.data;
          if (!this.record.person) {
            this.record.person = person;
          }
          this.menu_items = helpFunctions.getMenuItems(this.record.type);
          //contact don`t need notes because he view workers notes instead of his own
          if(this.record.type === 'worker') {
            this.getNotes();
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    download(file) {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/file/" + file.id, { responseType: "blob" })
        .then((response) => {
          let blob;
          if(file.content_type === 'text/html') {
            blob = new Blob([response.data], {type: "text/html"});
          }
          else {
            blob = new Blob([response.data], {type: "application/pdf"});
          }
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = file.name;
          link.click();
          URL.revokeObjectURL(link.href);
          this.loading = false;
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    cleanCache() {
      this.$toasted.success("cleaned_cache");
      localStorage.clear();
      window.location.reload();
    },
    save() {
      if(!this.loading) {
        if (this.record.person && this.record.person.phonenumber && this.record.person.phonenumber.charAt(0) != '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        } else if (this.record.person && this.record.person.mobilenumber && this.record.person.mobilenumber.charAt(0) != '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        } else {
          this.loading = true;
          this.$http
              .put(this.$store.getters.appUrl + "v2/" + this.page.name, this.record)
              .then((response) => {
                this.loading = false;
                if (response.status == 200) {
                  this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                  this.$store.dispatch("setLanguage", this.record.language);
                  this.load();
                } else {
                  var errorMessage = response.data.message;
                  var errors = Object.keys(response.data.message);
                  this.$toasted.error([errorMessage[errors[0]]]);
                }
              })
              .catch((error) => {
                if (this.$store.getters.isLoggedIn) {
                  this.$toasted.error(error);
                }
                this.loading = false;
              });
        }
      }
    },
    logout() {
      this.$store.dispatch("logout");
      return this.$router.push("/login");
    },
    async getInfo() {
      this.$vuetify.theme.themes.light.primary = "#FFFFFF";
      this.$vuetify.theme.themes.light.secondary = "#FFFFFF";
      this.$http
        .get(this.$store.getters.appUrl + "v2/info")
        .then((response) => {
          this.info = response.data;
          this.$store.dispatch("setColors", {
            primary_color: response.data.primary_color,
            secondary_color: response.data.secondary_color,
          });
          this.$vuetify.theme.themes.light.primary = response.data.primary_color;
          this.$vuetify.theme.themes.light.secondary = response.data.secondary_color;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    async getNotes() {
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "/notes")
        .then((response) => {
          this.notes = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    clickTab(tab) {
      this.tab = tab;
      if (this.$vuetify.breakpoint.smAndDown) {
        this.menu = false;
      }
    },
    clearSearch() {
      this.search = "";
    },
  },
  computed: {
    avatar() {
      if (this.record.person.worker && this.record.person.file) {
        return this.record.person.file.full_path;
      }
      else {
        return null;
      }
    },
    getWorkers() {
      if(this.search.length > 1) {
        return this.record.workers.filter((worker) => worker.name.toLowerCase().includes(this.search.toLowerCase()));
      }
      else {
        return this.record.workers;
      }
    },
  },
};
</script> 

<style scoped>
.v-toolbar__image {
  opacity: 0.2 !important;
}
img {
  width: 200px;
}
</style>